import { createStore } from "vuex";
import { auth } from "./auth.module";
import { register } from "./register.module";
import { company } from "./company.module";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { expenses } from "./expenses.module";
import { item } from "./item.module";
import { quotation } from "./quotation.module";
import { setting } from "./setting.module";
import { invoice } from "./invoice.module";
import { client } from "./client.module";
// import { v4 as uuidv4 } from 'uuid';

const secret_key = "smoothbalanceAES123";

const store = createStore({
  getters: {
    getSKey: () => {
      return secret_key;
    }
  },
  modules: {
    auth,
    register,
    company,
    expenses,
    item,
    quotation,
    setting,
    invoice,
    client
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => {
        let original_data = null;
        if (Cookies.get(key)) {
          var bytes = CryptoJS.AES.decrypt(Cookies.get(key)?.toString(), secret_key);
          original_data = bytes.toString(CryptoJS.enc.Utf8);
        }
        return original_data;
        // Cookies.get(key);
      },
      setItem: (key, value) => {
        const encrypted_value = CryptoJS.AES.encrypt(value.toString(), secret_key).toString();
        // const inOneMinutes = new Date(new Date().getTime() + 1 * 60 * 1000);
        // Encrypt the store using our encryption token stored in cookies.
        Cookies.set(key, encrypted_value, { expires: 1, secure: true });
      },
      removeItem: (key) => Cookies.remove(key),
    }
  })]
});

export default store;