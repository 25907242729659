import { nextTick } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
import Register from "../components/auth/Register";
import Regional from "../components/auth/Regional";
import UserDashboard from "../components/user/Dashboard";
import AuthMiddleware from '../middleware/auth.middleware';
import LandingPage from '../components/Landing';
import SoleTraderLimitedCompany from '../components/user/SoleTraderLimitedCompany';
import SoleTrader from '../components/user/SoleTrader';
import LimitedCompany from '../components/user/LimitedCompany';
import ItemList from '../components/items/List';
import AddListItem from '../components/items/AddListItem';
import PasswordConfirmation from '../components/auth/PasswordConfirmation';
import Logout from "../components/auth/Logout";
import store from '../store';
import ListExpense from "../components/expense/ExpenseComponent.vue";
import AddExpense from "../components/expense/AddExpenseComponent.vue";
import EditExpense from "../components/expense/EditExpenseComponent.vue";
import ListClient from "../components/clients/ListClient.vue";
import AddClient from "../components/clients/AddClient.vue";
import ListQuotation from "../components/quotation/QuotationComponent.vue";
import AddQuotation from "../components/quotation/AddQuotation.vue";
import ListInvoice from "../components/invoice/ListInvoice.vue";
import AddInvoice from "../components/invoice/AddInvoice.vue";
import ShowInvoice from "../components/invoice/ShowInvoice.vue";
import SettingComponent from "../components/settings/Setting.vue";
import SuccessStripe from "../components/stripe/Success.vue";
import ErrorStripe from "../components/stripe/Error.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: LandingPage,
        meta: {
            layout: 'login',
            title: "Dashboard",
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            layout: 'login',
            title: "Login",
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            layout: 'login',
            title: "register",
        },
    },
    {
        path: "/password-confirmation",
        name: "password-conrimation",
        component: PasswordConfirmation,
        meta: {
            layout: 'login',
            title: "password conrimation",
        },
    },
    {
        path: "/regional-settings",
        name: "regional-settings",
        component: Regional,
        meta: {
            layout: 'login',
            title: "regional settings",
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-passoword",
        component: ForgotPassword,
        meta: {
            layout: 'login',
            title: "forgot passoword",
        },
    },
    {
        path: "/dashboard",
        name: "user-dashboard",
        component: UserDashboard,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "user dashboard",
        },
    },
    {
        path: "/item-list",
        name: "item-list",
        component: ItemList,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "item list",
        },
    },
    {
        path: "/add-item",
        name: "add-item",
        component: AddListItem,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "add item",
        },
    },
    {
        path: "/complete-profile",
        name: "soletrader-limitedcompany",
        component: SoleTraderLimitedCompany,
        meta: {
            middleware: AuthMiddleware,
            layout: 'login',
            title: "soletrader limited company",
        },
    },
    {
        path: "/limited-company",
        name: "limited-company",
        component: LimitedCompany,
        meta: {
            middleware: AuthMiddleware,
            layout: 'login',
            title: "limited company",
        },
    },
    {
        path: "/sole-trader",
        name: "sole-trader",
        component: SoleTrader,
        meta: {
            middleware: AuthMiddleware,
            layout: 'login',
            title: "sole trader",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout
    },
    {
        path: "/list-expenses",
        name: "list-expenses",
        component: ListExpense,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "list expenses",
        },
    },
    {
        path: "/add-expense",
        name: "add-expense",
        component: AddExpense,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "add expense",
        },
    },
    {
        path: "/edit-expense/:id",
        name: "edit-expense",
        component: EditExpense,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "edit expense",
        },
    },
    {
        path: "/list-clients",
        name: "list-clients",
        component: ListClient,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "list clients",
        },
    },
    {
        path: "/add-client",
        name: "add-client",
        component: AddClient,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "add client",
        },
    },
    {
        path: "/list-quotation",
        name: "list-quotation",
        component: ListQuotation,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "list Quotation",
        },
    },
    {
        path: "/add-quotation",
        name: "add-quotation",
        component: AddQuotation,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "add quotation",
        },
    },
    {
        path: "/list-invoice",
        name: "list-invoice",
        component: ListInvoice,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "list invoice",
        },
    },
    {
        path: "/add-invoice",
        name: "add-invoice",
        component: AddInvoice,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "add invoice",
        },
    },

    {
        path: "/show-invoice",
        name: "show-invoice",
        component: ShowInvoice,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "invoice details",
        },
    },
    {
        path: "/setting",
        name: "setting",
        component: SettingComponent,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "Setting",
        },
    },

    {
        path: "/stripe-success",
        name: "stripe-success",
        component: SuccessStripe,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "Stripe Success",
        },
    },

    {
        path: "/stripe-error",
        name: "stripe-error",
        component: ErrorStripe,
        meta: {
            middleware: AuthMiddleware,
            layout: 'user',
            title: "Stripe error",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach((to, from, failure) => {
    nextTick(() => {
        to.meta.title ? (document.title = `${to.meta.title.replace(/\b\w/g, (match) =>
            match.toUpperCase()
        )} | Smoothbalance`) : 'Smoothbalance';
    });
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    let routes = ["login", "register", "regional-settings", "home", "forgot-password"];
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    } else if (routes.includes(to.name)) {
        if (store.getters['auth/getLoggedIn']) {
            next({ name: 'user-dashboard' })
        }
        // router.push('dashboard')
    }

    next();
});

export default router;