import { GET_QUOTATION } from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class QuotationService {
    getList() {
        return axios.post(GET_QUOTATION, null, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
}

export default new QuotationService();