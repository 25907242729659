<template>
  <!--header start-->
  <el-affix>
    <el-menu class="el-menu-top-navigation" mode="horizontal" :ellipsis="false" :router="true">
      <el-menu-item index="/">
        <img src="@/assets\images\system\smoothbalance_logo.png" alt="my-logo" />
        Smoothbalance
      </el-menu-item>

      <el-menu-item index="#" @click="callChildMethod">
        ...
      </el-menu-item>
      <el-menu-item><i class="fas fa-share-alt"></i>&nbsp;</el-menu-item>
      <el-menu-item><i class="fab fa-facebook-f"></i>&nbsp;</el-menu-item>
      <el-menu-item><i class="fab fa-twitter"></i>&nbsp;</el-menu-item>
      <el-menu-item><i class="fas fa-envelope"></i>&nbsp;</el-menu-item>
      <el-menu-item><i class="fas fa-comment"></i>&nbsp;</el-menu-item>

      <div class="flex-grow" />

      <el-sub-menu index="22">
        <template #title>
          <el-badge v-model="notification_count" class="item badge-notification">
            <i class="fas fa-bell"></i>
          </el-badge>
        </template>
        <el-menu-item index="/">Dashboard</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="2">
        <template #title>
          <img src="@/assets/images/system/user-F.png" />
          &nbsp; Hello, {{ welcome_name }}
        </template>
        <el-menu-item index="/">Dashboard</el-menu-item>
        <el-menu-item index="/setting">Settings</el-menu-item>
        <el-menu-item index="/logout">Sign out &nbsp;
          <img src="@/assets/images/system/logout.svg" class="append" /></el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-affix>
  <!--header end-->

  <!--dashboard container start-->
  <section class="dashboard-container">
    <!--dashboard left side bar start-->
    <AsideMenu ref="side_menu" />
    <!--dashboard left sidebar ended-->
    <div :class="['dashboard-body', { 'full-width-body': isMenuHidden }]">
      <!-- view -->
      <router-view />
    </div>
    <!--dashboard main body end-->
  </section>
  <!--ended dashboad container-->
</template>
<script>
// Please just use `@` to refer to the root "src" directory of the project
import homeSrc from "@/assets/images/system/home.png";
import AsideMenu from "@/components/partials/AsideMenu.vue";

export default {
  name: "UserDashboard",
  components: {
    AsideMenu,
  },
  data() {
    return {
      loadingInstance: null,
      user: this.$store.state.auth.user,
      welcome_name: "",
      notification_count: '',
      isMenuHidden: false
    };
  },
  mounted() {
    this.welcome_name = this.$store.state.auth.user.welcomename;
  },
  setup() {
    return {
      homeSrc,
    };
  },
  methods: {
    callChildMethod() {
      // Access the child component instance using $refs
      this.isMenuHidden = !this.isMenuHidden;
      if (this.$refs.side_menu) {
        this.$refs.side_menu.collapseToggle();
      }
    },

  }
};
</script>

<style>
.dashboard-container .dashboard-body {
  /* margin-top: 45px; */
}

.dashboard-body {
  transition: width 0.3s ease-in-out;
}

.full-width-body {
  width: 100%;
}
</style>