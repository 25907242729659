import {USER_LOGIN, USER_REGISTRATION, FORGOT_PASSWORD, USER_UPDATE_COUNTRY, USER_UPDATE_LANGUAGE, USER_UPDATE_CURRENCY, USER_GET_PROFILE} from '../config/url';
import axios from "axios";
import store from '../store';
import authHeader from './auth-header';

class AuthService {
    login(user) {
        return axios
            .post(USER_LOGIN, {
                email: user.email, password: user.password, version: "web", token: user.recaptcha_token
            })
            .then(response => {
                // store token
                if (response.data.api_token) {
                    store.commit('auth/updateLastActiveTime');
                    store.dispatch('auth/storetoken', response.data.api_token);
                }

                if (user.rememberMe) {
                    // Store user credentials in localStorage
                    localStorage.setItem('user-details', JSON.stringify(user));
                } else {
                    // Clear user credentials from localStorage
                    localStorage.removeItem('user');
                }

                return response.data;
            });
    }

    updateCountry(country) {
        return axios
            .post(USER_UPDATE_COUNTRY, country, {
                headers: authHeader()
            })
            .then(response => {
                return response;
            });
    }

    updateLanguage(language) {
        return axios
            .post(USER_UPDATE_LANGUAGE, language, {
                headers: authHeader()
            })
            .then(response => {
                return response;
            });
    }

    updateCurrency(currency) {
        return axios
            .post(USER_UPDATE_CURRENCY, currency, {
                headers: authHeader()
            })
            .then(response => {
                return response;
            });
    }

    getProfile() {
        return axios
            .get(USER_GET_PROFILE, {
                headers: authHeader()
            })
            .then(response => {
                return response;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(USER_REGISTRATION, {
            user_type: 'web', app_type: 'SmoothBalance', fullname: user.fullname, email: user.email, password: user.password, country: user.country, currency: user.currency, version: "web", language: user.language
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    forgotPassword(user) {
        return axios.post(FORGOT_PASSWORD, {
            email: user.email
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
}

export default new AuthService();