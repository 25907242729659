import { GET_INVOICE_CLIENT_LIST,GET_CLIENT, CREATE_CLIENT, DELETE_CLIENT, STATES_LIST } from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class ClientService {
    getList() {
        return axios.get(GET_CLIENT, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
    getListByUser() {
        return axios.get(GET_INVOICE_CLIENT_LIST, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    createClient(client_data) {
        return axios.post(CREATE_CLIENT, client_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    deleteClient(client_id) {
        return axios.post(DELETE_CLIENT, client_id, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }

    fetchStateByCountry(country_id) {
        return axios.post(STATES_LIST, country_id, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw new Error(error)
        });
    }
}

export default new ClientService();