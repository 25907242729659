<template>
    <div>
        <!--dashboard main body start-->
        <div class="dashboard-body dashboard-invoice">
            <div class="dashboard-body-content d-flex align-items-start flex-column">
                <div class="page-global-header px-0">
                    <div class="row justify-content-end mx-0">
                        <form class="base-form">
                            <div class="row d-flex align-items-center justify-content-between flex-row">
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="input-group input-group--3d p-0">
                                        <div class="input-group-text default prepend">
                                            <img src="@/assets/images/system/add-user-blue.png" />
                                        </div>
                                        <input type="text" name="itemTitle" class="form-control"
                                            placeholder="Type item name or title*" v-model="clientName"
                                            @click="getClientData" data-bs-target="#getClient" data-bs-toggle="modal" />
                                        <div class="input-group-text transparent p-0 append flush-before">
                                            <button type="button" name="btn" class="btn btn-white _sm"><i
                                                    class="fa fa-search" data-bs-target="#getClient" data-bs-toggle="modal"
                                                    @click="getClientData"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="input-group p-0">
                                        <span class="input-group-text prepend flush-after">
                                            Date
                                        </span>
                                        <input type="date" name="Invoice_date" class="form-control datepicker"
                                            v-model="invoice_date" onfocus="this.showPicker()" />

                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                    <div class="input-group p-0">
                                        <span class="input-group-text prepend flush-after">
                                            Terms
                                        </span>
                                        <select name="paymenetMethod" v-model="terms" class="form-select" required>
                                            <option v-for="day in 32" :key="day" :value="day - 1">{{ day - 1 }} Days
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-auto">
                                    <button class="btn btn-white">Invoice No: {{ invoiceNumber }}</button>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>

                <div class="w-100 my-3 px-2">
                    <div class="white-shadebox">
                        <ul class="nav nav-fill nav--iconicshade">
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" data-bs-target="#addItemToList"
                                    data-bs-toggle="modal">
                                    <span class="nav-icon shade">
                                        <img src="@/assets/images/system/plus-orange.png"></span>
                                    <span class="nav-text">Add
                                        Item</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" data-bs-target="#addPayment" data-bs-toggle="modal">
                                    <span class="nav-icon shade"><img src="@/assets/images/system/dollar.png"></span>
                                    <span class="nav-text">Payment</span> </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" data-bs-target="#addDiscount"
                                    data-bs-toggle="modal">
                                    <span class="nav-icon shade">
                                        <img src="@/assets/images/system/percent-orange.png">
                                    </span>
                                    <span class="nav-text">Discount</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" data-bs-target="#addAttachment"
                                    data-bs-toggle="modal">
                                    <span class="nav-icon shade"><img src="@/assets/images/system/camera-blues.png">
                                        <span class="has-badge" v-if="image_count > 0">{{ image_count }}</span>
                                    </span>
                                    <span class="nav-text">Attachment</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:;" class="nav-link" data-bs-target="#addNote" data-bs-toggle="modal">
                                    <span class="nav-icon shade"><img src="@/assets/images/system/edit-orange.png">
                                        <span class="has-badge" v-if="note != ''"><i class="fa fa-check"></i></span>
                                    </span>
                                    <span class="nav-text">Note</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="page-body">
                    <div class="row has-vr-devider position-relative mt-5 mx-0">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">

                            <div class="contact-card-group">
                                <!--repeatable block-->
                                <div class="card card-flex-row _v2" v-for="(invoice, index) in selectedItemsList"
                                    :key="index" @click="selectClient">
                                    <div class="card-header">
                                        <span class="badge-count">{{ (index += 1) }}.</span>
                                    </div>
                                    <div class="card-body">

                                        <ul class="cmn-vrList">
                                            <li class="list-title">{{ invoice.category_type }}</li>
                                            <li class="sub-title">{{ invoice.name }}</li>

                                            <li class="mt-2">{{ invoice.description }}
                                            </li>

                                            <li class="price-tag">{{ parseInt(invoice.quantity) }} x
                                                {{ parseFloat(invoice.unit_price).toFixed(2) }}</li>
                                        </ul>
                                    </div>
                                    <div class="card-footer justify-content-between flex-column align-items-end">
                                        <ul class="hr-navList">
                                            <li class="nav-item ellipsis-dropdown dropdown">
                                                <a href="#" class="nav-link dropdown-toggle no-caret" id="edit-act-1"
                                                    data-bs-toggle="dropdown" aria-expanded="false"><i
                                                        class="fas fa-ellipsis-v"></i></a>
                                                <ul class="dropdown-menu" aria-labelledby="edit-act-1">
                                                    <li><a class="dropdown-item delete" href="javascript:;"
                                                            @click="removeItem(invoice.item_no)"><i class="fa fa-times"></i>
                                                        </a></li>

                                                </ul>
                                            </li>
                                        </ul>
                                        <div class="ftr-price-tag">
                                            &pound;{{ parseInt(invoice.quantity) * parseFloat(invoice.unit_price).toFixed(2)
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!--ended repeatable block-->
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 ms-auto">
                            <form class="base-form ps-xl-5">
                                <div class="row m-0 mb-3 pe-xl-5">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Amount

                                        </span>
                                        <input type="text" name="amount" class="form-control" v-model="totalPrice" disabled/>
                                    </div>
                                </div>

                                <div class="row m-0 mb-3 pe-xl-5" v-if="discount > 0">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Discount ({{ isPercentChecked ? "%" : "$" }})
                                        </span>
                                        <input type="text" name="amount" class="form-control" v-model="discount" disabled/>
                                    </div>
                                </div>

                                <div class="row m-0 mb-3 pe-xl-5">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Sub-Total
                                        </span>
                                        <input type="text" name="amount" class="form-control" v-model="subTotalPrice" disabled/>
                                    </div>
                                </div>

                                <div class="row m-0 mb-3 pe-xl-5" v-if="tax_1_status_enable">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Tax (Vat) ({{ tax_1_rate }}%)
                                        </span>
                                        <input type="text" name="amount" class="form-control" disabled />
                                    </div>
                                </div>

                                <!-- <div class="row m-0 mb-3 pe-xl-5">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Tax 2 (15%)
                                        </span>
                                        <input type="text" name="amount" class="form-control" />
                                    </div>
                                </div> -->

                                <!-- <div class="row m-0 mb-3 pe-xl-5">
                                    <div class="input-group _group-xl flush-shadow p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Total
                                        </span>
                                        <input type="text" name="amount" class="form-control" disabled/>
                                    </div>
                                </div> -->

                                <div class="row m-0 mb-3 pe-xl-5">
                                    <div class="input-group _group-xl input-group--3d flush-shadow group-half p-0">
                                        <span class="input-group-text justify-content-end prepend flush-after">
                                            Outstanding Due
                                        </span>
                                        <div class="form-floating">
                                            <label class="floating-label highlight">{{ currency_symbol }}</label>
                                            <input type="text" name="amount" class="form-control highlight"
                                                v-model="outstanding_due" disabled/>
                                        </div>
                                    </div>
                                </div>


                                <div class="row m-0 mb-3 pe-xl-5 mt-5 d-flex justify-content-end">
                                    <div class="col-sm-12 p-0 d-flex align-items-center justify-content-end">
                                        <button class="btn btn-offWhite">Cancel</button>
                                        <button type="button" class="btn btn-success btn-shadow ms-5"
                                            @click="saveInvoice">Next</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--dashboard main body end-->

        <!--add browse Modal for client-->
        <div class="modal fade mdl-messaging _info" id="getClient" aria-hidden="true" aria-labelledby="getClientLabel"
            tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">Client</div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="row justify-content-center mb-4">
                        <div class="col-sm-7 mt-2" v-for="(client, index) in client_server_data" :key="index"
                            @click="selectClient(client)">
                            <!--repeatable block-->
                            <div class="card card-flex-row">
                                <div class="card-header">
                                    <i class="far fa-user"></i>
                                </div>
                                <div class="card-body">
                                    <ul class="cmn-vrList">
                                        <li class="list-title">
                                            <span class="card-count">1.</span>
                                            <label>Co:</label>{{ client.business_name }}
                                        </li>
                                        <li class="sub-title">
                                            <label>N:</label>{{ client.name }}
                                        </li>
                                        <li class="txt-alert">
                                            <label>E:</label>{{ client.email }}
                                        </li>
                                        <li><label>T:</label>{{ client.phone1 }}</li>
                                    </ul>
                                </div>
                                <div class="card-footer">

                                </div>
                            </div>
                            <!--ended repeatable block-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--add browse Modal file for item-->
        <div class="modal fade mdl-messaging _info" id="addItemToList" aria-hidden="true"
            aria-labelledby="addItemToListLabel" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">Add/Browse Item</div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <AddItem from-view="add_invoice" @show-list-of-items="showItemListPopup"></AddItem>
                </div>
            </div>
        </div>

        <!--add brows Modal file for item-->
        <el-dialog v-model="dialogVisibleItemsPopup" title="Item List" width="40%">
            <ItemListOnlyInvoice></ItemListOnlyInvoice>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisibleItemsPopup = false">Close</el-button>
                </span>
            </template>
        </el-dialog>

        <!--add discount-->
        <div class="modal fade mdl-messaging _info" id="addDiscount" aria-hidden="true" aria-labelledby="addDiscountLabel"
            tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">Discount</div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="row d-flex align-items-center mx-4">
                        <div class="col-8">
                            <el-checkbox v-model="isPercentChecked" @change="handleCheckboxChange('percent')"
                                label="Percent (%)" size="large" />
                            <el-checkbox v-model="isCashChecked" @change="handleCheckboxChange('cash')" label="Cash"
                                size="large" />
                        </div>
                        <div class="col-8">
                            <el-input-number v-model="discount" :min="0" :max="100" class="w-50" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--add attachment-->
        <div class="modal fade mdl-messaging _info" id="addAttachment" aria-hidden="true"
            aria-labelledby="addAttachmentLabel" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">Discount</div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="row mt-2rem">
                        <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
                            <el-carousel :interval="2000">
                                <el-carousel-item v-for="(image_file, key) in image_list" :key="key">
                                    <img class="preview" :ref="'image' + parseInt(key)" />
                                </el-carousel-item>
                            </el-carousel>

                            <div class="row">
                                <div class="col-sm-12 d-flex align-items-center justify-content-center">
                                    <h6>Preview ({{ image_list.length }})</h6>
                                </div>
                            </div>
                            <div class="col-sm-12 d-flex align-items-center justify-content-center">
                                <div class="custome-fileupload-circle">
                                    <input type="file" class="file-input expense-file" accept="image/jpeg, image/png"
                                        @change="onFileChange" multiple="multiple" />
                                    <div class="file-content">
                                        <div class="file-icon">
                                            <i class="fa fa-camera"></i>
                                        </div>
                                        <div class="file-info-text">Add Attachment</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 d-flex align-items-center justify-content-center mt-2">
                                <small>Note: Upload jpg/png only.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--add note-->
        <div class="modal fade mdl-messaging _info" id="addNote" aria-hidden="true" aria-labelledby="addNoteLabel"
            tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">Discount</div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="row mt-2rem">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <el-input v-model="note" :rows="3" type="textarea" placeholder="Please input" />
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!--add payment-->
        <div class="modal fade mdl-messaging _info" id="addPayment" aria-hidden="true" aria-labelledby="addPaymentLabel"
            tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-dialog-header">
                    <div class="header-title">
                        Payment
                    </div>
                </div>
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="mt-2rem ps-4 pe-4 message-card align-items-center">
                        <!-- <div class="mb-3">
                            <div class="input-group input-group--3d _gray p-0">
                                <div class="input-group-text prepend">
                                    <i class="fa fa-envelope"></i>
                                </div>
                                <select class="form-select" v-model="payment_method" placeholder="Payment Method">
                                    <option v-for="item in payment_method_list" :key="item" :value="item" >{{ item }}</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="row m-0 mb-3 ms-3 pe-xl-5">
                            <div class="input-group _group-xl p-0">
                                <span class="input-group-text prepend bg-bostonBlue flush-after">
                                    Payment Method
                                    <span class="required">*</span>
                                </span>
                                <select name="payment_method" v-model="payment_method" class="form-select" required>
                                    <option value="" selected disabled>Please Select</option>
                                    <option v-for="item in payment_method_list" :key="item" :value="item">{{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row m-0 mb-3 ms-3 pe-xl-5">
                            <div class="input-group _group-xl p-0">
                                <span class="input-group-text prepend bg-bostonBlue flush-after">
                                    Expense Date
                                </span>
                                <input type="date" name="payment_date" class="form-control" v-model="payment_date"
                                    onfocus="this.showPicker()" />
                            </div>
                        </div>
                        <div class="row m-0 mb-3 ms-3 pe-xl-5">
                            <div class="input-group _group-xl p-0">
                                <span class="input-group-text prepend bg-bostonBlue flush-after">
                                    Due Amount
                                </span>
                                <input type="text" name="due" placeholder="Due Amount" class="form-control"
                                    v-model="outstanding_due" disabled />

                            </div>
                        </div>
                        <div class="row m-0 mb-3 ms-3 pe-xl-5">
                            <div class="input-group _group-xl p-0">
                                <span class="input-group-text prepend bg-bostonBlue flush-after">
                                    Pay Now
                                </span>
                                <input type="text" name="pay_now_amount" placeholder="Pay now" class="form-control"
                                    v-model="pay_now_amount" />

                            </div>
                        </div>
                        <div class="row m-0 mb-3 ms-3 pe-xl-5">
                            <textarea type="text" name="pay_now_description" placeholder="Descrption" class="form-control"
                                v-model="pay_now_description"></textarea>
                        </div>
                        <div class="row m-0 mb-3 ms-3 pe-xl-5 mt-5 d-flex justify-content-end">
                            <div class="col-sm-12 p-0 d-flex align-items-center justify-content-end">
                                <button class="btn btn-offWhite" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                <button type="button" class="btn btn-success btn-shadow ms-5"
                                    @click="addPayment">Next</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";
import AddItem from "@/components/items/AddItem.vue";
import ItemListOnlyInvoice from "@/components/items/ItemListOnly.vue";

export default {
    name: "AddInvoice",
    props: {
        title: String,
        // rest of the blog post props
    },
    components: {
        AddItem,
        ItemListOnlyInvoice
    },
    data() {
        return {
            show_total_expense: 0,
            invoice_server_data: [],
            dialogVisibleItemsPopup: false,
            isPercentChecked: true,
            isCashChecked: false,
            image_count: 0,
            // needed in form
            invoice_date: "",
            terms: 28,
            invoiceNumber: 0,
            totalPrice: 0,
            subTotalPrice: 0,
            client_server_data: [],
            client: '',
            clientName: '',
            tax_1_rate: 0,
            tax_1_status_enable: false,
            tax_2_status_enable: false,
            tax_2_rate: 0,
            discount: 0,
            image_list: [],
            is_image: 'N',
            note: '',
            payment_method_list: '',
            payment_method: '',
            payment_date: '',
            outstanding_due: '',
            pay_now_amount: '',
            pay_now_description: '',
            payment: [],
            currency_symbol: '$',
            currency_id: this.$store.state.auth.user.currency_id,
        };
    },
    created() {
        this.runMountedFunctions();
    },
    watch: {
        totalPriceCalculation() {
            this.totalPrice = this.totalPriceCalculation;
        },
        subtotalCalculation() {
            this.subTotalPrice = this.subtotalCalculation;
            this.outstanding_due = this.subtotalCalculation;
        },
    },
    computed: {
        pageTitle() {
            return (document.title = `${this.title.replace(/\b\w/g, (match) =>
                match.toUpperCase()
            )} | Smoothbalance`);
        },
        selectedItemsList() {
            let selectedItems = this.$store.getters['item/getSelectedItemsList'];
            return selectedItems;
        },
        totalPriceCalculation() {
            let selectedItems = this.$store.getters['item/getSelectedItemsList'];
            return selectedItems.reduce((total, item) => {
                const itemPrice = parseInt(item.quantity) * parseFloat(item.unit_price);
                return total + itemPrice;
            }, 0);
        },
        subtotalCalculation() {
            const total = this.totalPriceCalculation;
            let discountedTotal = 0;
            if (this.isPercentChecked) {
                discountedTotal = total - (total * (this.discount / 100));
            } else {
                discountedTotal = total - this.discount;
            }

            return discountedTotal;
        },
        currencyOptions() {
            if (this.currency) {
                return this.currency.map((v) => {
                    return {
                        id: v.id,
                        name: v.name,
                        symbol: v.symbol,
                    };
                });
            }
            return [];
        },
    },
    methods: {
        runMountedFunctions() {
            this.fetchCurrency();
            this.getInvoiceNumber();
            this.getTaxDetails();
            this.fetchPaymentMethod();
        },
        getInvoiceItems() {
            const selectedItems = this.$store.getters['item/getSelectedItemsList'];

            // Map through the selected items to transform them
            const transformedItems = selectedItems.map(item => {
                const { name, unit_price, description, quantity, type } = item;

                // Calculate the total by multiplying quantity and unit_price
                const total = parseFloat(unit_price) * parseFloat(quantity);

                // Return a new object with required keys and the calculated total
                return {
                    name,
                    unit_price,
                    description,
                    quantity,
                    type,
                    total // Add the calculated total to the object
                };
            });
            return transformedItems;
        },
        saveInvoice() {
            const data = {
                invoice_status: 'draft',
                invoice_type: 'draft',
                invoice_date: this.invoice_date,
                invoice_number: this.invoiceNumber,
                client_id: this.client,
                validity_term: this.terms,
                currency_id: this.currency_id,
                payment: this.payment,
                notes: this.note,
                invoice_items: this.getInvoiceItems()
            }

            this.$store
                .dispatch("invoice/saveInvoice", data)
                .then((res) => {
                    if (res.data.status == 1) {
                        localStorage.setItem('invoice-details', JSON.stringify(res.data.response));
                        this.$router.push({ name: 'show-invoice' });
                    } else {
                        throw new Error(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                });
        },
        changeCurrency() {
            this.currency_symbol = this.currency.find(
                (currency) => currency.id === this.form.currency_id
            ).symbol;
        },
        async fetchCurrency() {
            this.currency = await this.axios
                .get(CURRENCY_LIST)
                .then(function (res) {
                    return res.data.response;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async fetchPaymentMethod() {
            this.payment_method_list = await this.$store
                .dispatch("expenses/getPaymentOptions")
                .then((res) => {
                    // console.log(res);
                    if (res.status == 1) {
                        return res.response;
                        // this.$router.push({ name: 'user-dashboard' });
                    } else {
                        throw new Error(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    // this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                });
        },
        getTaxDetails() {
            this.$store
                .dispatch("setting/getTaxDetails")
                .then((res) => {
                    if (res.data.status == 1 && res.data.response != null) {
                        this.tax_1_rate = res.data.tax1_rate;
                        this.tax_1_status_enable = res.data.is_tax1_enable ? true : false;
                        this.tax_2_rate = res.data.tax2_rate;
                        this.tax_2_status_enable = res.data.is_tax2_enable ? true : false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast.error("Something went wrong. Please try again.");
                });
        },
        handleCheckboxChange(type) {
            if (type === 'percent' && this.isPercentChecked) {
                this.isCashChecked = false;
            } else if (type === 'cash' && this.isCashChecked) {
                this.isPercentChecked = false;
            }
        },
        showLoading() {
            this.loadingInstance = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
                fullscreen: true
            });
        },
        hideLoading() {
            this.loadingInstance.close();
        },
        filterExpenses() {
            console.log(this.search);
        },
        showItemListPopup() {
            this.dialogVisibleItemsPopup = true;
        },
        removeItem(item_no) {
            this.$store.commit('item/removeFromRecentItemsInvoice', item_no);
        },
        getClientData() {
            this.showLoading();
            this.$store
                .dispatch("client/getListByUser", this.form)
                .then((res) => {
                    if (res.status == 1) {
                        this.client_server_data = res.response;
                        // this.$toast.success("Fetch data Successful.");
                        // this.$router.push({ name: 'user-dashboard' });
                    } else {
                        throw new Error(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.client_server_data = [];
                    // this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                    this.hideLoading();
                });
        },
        selectClient(client) {
            this.client = client.client_id;
            this.clientName = client.name;
        },
        getInvoiceNumber() {
            this.showLoading();
            this.$store
                .dispatch("invoice/getInvoiceNumber")
                .then((res) => {
                    if (res.data.status == 1) {
                        this.invoiceNumber = res.data.response.response
                        console.loog(res)
                    } else {
                        throw new Error(res);
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.status === 404) {
                        this.$toast.error("Resource not found");
                    }
                    // this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                    this.hideLoading();
                });
        },
        onFileChange(e) {
            // let vm = this;
            this.image_list = new Array(...e.target.files);
            const files = e.target.files;
            const maxSize = 512 * 1024; // 512 KB in bytes

            for (let i = 0; i < this.image_list.length; i++) {
                const file = files[i];
                if (file.size > maxSize) {
                    this.$toast.error(`File "${file.name}" exceeds the maximum allowed size of 512 KB.`);
                    // Optionally clear the input or handle the error in your application
                    e.target.value = null;
                    break;
                }

                let reader = new FileReader(); //instantiate a new file reader
                reader.addEventListener(
                    "load",
                    function () {
                        this.$refs["image" + parseInt(i)][0].src = reader.result;
                    }.bind(this),
                    false
                ); //add event listener

                reader.readAsDataURL(this.image_list[i]);
                if (this.image_list.length > 0) {
                    this.image_count = this.image_list.length;
                    this.is_image = "Y";
                }
            }

            this.image_list = this.image_list.filter(element => element.size <= (512 * 1024))
        },
        addPayment() {
            if (this.pay_now_description == "" || this.pay_now_amount == "" || this.payment_date == "" || this.outstanding_due == "" || this.payment_method == "") {
                return false;
            }
            // Create a payment object based on the input values
            const newPayment = {
                submitted_amount: this.pay_now_amount,
                submission_date: this.payment_date,
                amount: this.outstanding_due,
                status: this.pay_now_amount >= this.outstanding_due ? 'fully_paid' : 'partially_paid',
                method: this.payment_method,
                description: this.pay_now_description
            };

            // Push the newPayment object into the payment array
            this.payment.push(newPayment);

            // Clear the input fields or perform any other necessary actions after adding the payment
            this.pay_now_amount = '';
            this.payment_date = '';
            this.outstanding_due = '';
            this.payment_method = '';
            this.pay_now_description = '';

            let selectedItems = this.$store.getters['item/getSelectedItemsList'];
            let total_price = selectedItems.reduce((total, item) => {
                const itemPrice = parseInt(item.quantity) * parseFloat(item.unit_price);
                return total + itemPrice;
            }, 0);


            let discountedTotal = 0;
            if (this.isPercentChecked) {
                discountedTotal = total_price - (total_price * (this.discount / 100));
            } else {
                discountedTotal = total_price - this.discount;
            }

            const paymentAmountSum = this.payment.reduce((total, item) => {
                return total + parseFloat(item.submitted_amount);
            }, 0);

            this.outstanding_due = parseFloat(discountedTotal) - paymentAmountSum;
        }
    },
};
</script>
    
<style scoped>
.el-carousel {
    border: 2px solid #ccc;
    padding: 10px;
}

.preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border: 2px solid #fff;
}
</style>