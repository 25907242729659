<template>
    <div>
        <iframe :src="pdfUrl" width="100%" height="600px" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    name: "AddInvoice",
    props: {
        title: String,
        // rest of the blog post props
    },
    data() {
        return {
            pdfUrl: ''
        };
    },
    created() {
        // Check on component creation if the item exists in local storage
        this.pdf_url_server();
    },
    computed: {
        pageTitle() {
            return (document.title = `${this.title.replace(/\b\w/g, (match) =>
                match.toUpperCase()
            )} | Smoothbalance`);
        },

    },
    methods: {
        pdf_url_server() {
            const retrievedItem = JSON.parse(localStorage.getItem('invoice-details'));
            
            if (retrievedItem) {
                this.pdfUrl = retrievedItem.pdfLink;
            }
        }
    }
}
</script>