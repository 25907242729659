import { GET_INVOICE, GET_INVOICE_NUMBER, SAVE_INVOICE } from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class InvoiceService {
    getList() {
        return axios.post(GET_INVOICE, null, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    getInvoiceNumber() {
        return axios.get(GET_INVOICE_NUMBER, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    saveInvoice(invoice_data) {
        return axios.post(SAVE_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }
}

export default new InvoiceService();