<template>
    <ul class="dashboard-quick-nav d-flex justify-content-center align-items-center h-100 d-inline-block">
      <li class="nav-item">
        <router-link @click="!isProfileComplete ? registrationCompleteModal() : ''" :class="`nav-link _quotation`" to="/list-quotation">
          <span class="link-icon"><i class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i>
            </span></span>
          <span class="link-text"> Quotation </span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link @click="!isProfileComplete ? registrationCompleteModal() : ''" :class="`nav-link _invoice`" to="/list-invoice">
          <span class="link-icon"><i class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i>
            </span></span>
          <span class="link-text"> Invoice </span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link @click="!isProfileComplete ? registrationCompleteModal() : ''" :class="`nav-link _expense`" to="/add-expense">
          <span class="link-icon"><i class="fas fa-file-alt"></i><span class="badge"><i class="fa fa-plus"></i>
            </span></span>
          <span class="link-text"> Expense </span>
        </router-link>
      </li>
    </ul>

    <button type="button" ref="myBtn" class="btn btn-primary" style="visibility: hidden" data-bs-toggle="modal"
      data-bs-target="#is-registered"></button>
    <!-- Modal -->
    <div class="modal fade" id="is-registered" tabindex="-1" aria-labelledby="is-registeredLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- <div class="modal-header">
                    <h5 class="modal-title" id="is-registeredLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> -->
          <div class="modal-body">
            Your registration is not complete.
            <span><router-link style="display: initial" @click="!isProfileComplete ? registrationCompleteModal() : ''"
                :to="{ name: 'soletrader-limitedcompany' }">
                Click here
              </router-link>
            </span>
            to complete the registration process.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <!-- <button type="button" class="btn btn-primary">Continue to complete registration</button> -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "Dashboard",

  data() {
    return {
      loadingInstance: null,
      isProfileComplete: false,
    };
  },
  mounted() {
    this.isProfileComplete = this.$store.getters["auth/getProfileCompleteStatus"];
  },

  setup() {
    const myBtn = ref(null);
    function registrationCompleteModal() {
      const buttonElement = this.$refs.myBtn;
      buttonElement.click();
    }
    onMounted(() => { });

    return {
      myBtn,
      registrationCompleteModal,
    };
  },
};
</script>