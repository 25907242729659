import AuthService from '../services/auth.service';
import store from '../store'

// const vuex = Cookies.get('vuex') ? JSON.parse(Cookies.get('vuex')): null;
const vuex = store;
const user = vuex?.auth

const localStorageKey = 'auth_last_active_time';

const initialState = user
    ? { status: { loggedIn: true }, user, isProfileComplete: false, access_token: "", lastActiveTime: Date.now(), isProfileSubscriptionExpired: false, NoOFDaysFreeUsage: false }
    : { status: { loggedIn: false }, user: null, isProfileComplete: false, access_token: "", lastActiveTime: null, isProfileSubscriptionExpired: false, NoOFDaysFreeUsage: false };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        getLoggedIn: (state) => {
            return state.status.loggedIn ? state.status.loggedIn : false;
        },
        getUserData: (state) => {
            return state.user;
        },
        getAccessToken: (state) => {
            return state.access_token;
        },
        getProfileCompleteStatus: (state) => {
            if ((state.user.company_name != "N/A" || state.user.company_name != null) && state.user.companytype != "N/A") {
                state.isProfileComplete = true;
            }
            return state.isProfileComplete;
        },
        getUserSubscriptionExpire: (state) => {
            state.isProfileSubscriptionExpired = false;

            if (parseInt(state.user.subscription_details.noOfDaysRemaining) <= 0) {
                state.isProfileSubscriptionExpired = true;
            }
            return state.isProfileSubscriptionExpired;
        },
        getUserNoOFDays: (state) => {
            state.NoOFDaysFreeUsage = false;

            if (parseInt(state.user.no_of_days_of_free_usage) <= 0) {
                state.NoOFDaysFreeUsage = true;
            }
            return state.NoOFDaysFreeUsage;
        },
        getLastActiveTime: (state) => {
            return state.lastActiveTime;
        }
    },
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    commit('updateLastActiveTime');
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    AuthService.logout();
                    return Promise.reject(error);
                }
            );
        },
        storetoken({ commit }, token) {
            commit('savetoken', token);
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        forgotPassword({ commit }, user) {
            return AuthService.forgotPassword(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        updateCurrency({ commit }, currency) {
            return AuthService.updateCurrency(currency).then(
                response => {
                    console.log(commit);
                    // commit('save', expenses);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateLanguage({ commit }, language) {
            return AuthService.updateLanguage(language).then(
                response => {
                    console.log(commit);
                    // commit('save', expenses);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        updateCountry({ commit }, country) {
            return AuthService.updateCountry(country).then(
                response => {
                    console.log(commit);
                    // commit('save', expenses);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        refreshProfile({ commit }) {
            return AuthService.getProfile().then(
                response => {
                    commit('refreshUserData', response.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        savetoken(state, token) {
            state.access_token = token;
        },
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user.response;
            state.lastActiveTime = Date.now();
            localStorage.setItem(localStorageKey, state.lastActiveTime);
        },
        refreshUserData(state, user) {
            state.user = user.response;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.lastActiveTime = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        updateLastActiveTime(state) {
            state.lastActiveTime = Date.now();
            localStorage.setItem(localStorageKey, state.lastActiveTime);
        }
    }
};