import quotationService from "../services/quotation.service";

const initialState = {
    list_quotation: []
}

export const quotation = {
    namespaced: true,
    state: initialState,
    getters: {
        getQuotation: (state) => {
            return state.list_quotation;
        },
        getQuotationEditData: ({ quotationById }) => (id) => quotationById[id]
    },
    actions: {
        getList({ commit }, quotation) {
            return quotationService.getList(quotation)
                .then((response) => {
                    commit('listQuotation', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
    },
    mutations: {
        save(state, quotation) {
            Object.assign(state, quotation);
        },
        listQuotation(state, quotation) {
            state.list_quotation = quotation.data.response;
            state.quotationById = quotation.data.response.reduce((acc, quotation) => {
                acc[quotation.id] = quotation;
                return acc;
            }, {});
            localStorage.setItem('list-quotation', JSON.stringify(quotation.data.response));
        },
    }
}