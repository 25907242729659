import ItemService from "../services/item.service";

const initialState = {
    items: {
        id: "", //required
        item_no: "", //required
        name: "", //required
        description: "", //required
        unit_price: "", //optional
        quantity: "", //required
        type: "", //required
        created_at: "", //required
        created_by: "", //optional
        active: "", //required
        category_id: "", //required
    },
    item_number_generated: '',
    item_list: [],
    item_category: [],
    item_added_recently: [],
    selectedItemsList: []
}

export const item = {
    namespaced: true,
    state: initialState,
    getters: {
        get_item_added_recently(state) {
            return state.item_added_recently;
        },
        getSelectedItemsList(state) {
            return state.selectedItemsList;
        }
    },
    actions: {
        getList({ commit }, item) {
            return ItemService.getList(item)
                .then(response => {
                    commit('save', response.data);
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        generateItemNumber({ commit }, item) {
            return ItemService.generateItemNumber(item).then(
                response => {
                    commit('saveItemNumber', response.data.response);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        createItem({ commit }, item) {
            return ItemService.createItem(item).then(
                response => {
                    commit('addRecentItemsAddPage', item);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        fetchCategory({ commit }, item) {
            return ItemService.fetchCategory(item).then(
                response => {
                    commit('itemCategory', response.data.response);
                    return Promise.resolve(response.data.response);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        createCategory({ commit }, item) {
            return ItemService.createCategory(item).then(
                response => {
                    console.log(commit);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deleteCategory({ commit }, item) {
            return ItemService.deleteCategory(item).then(
                response => {
                    console.log(commit);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deleteItem({ commit }, item) {
            return ItemService.deleteItem(item).then(
                response => {
                    commit('removeItemFromRecentList', item.item_no);
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        save(state, item) {
            state.item_list = item.response;
        },
        saveItemNumber(state, itemNumber) {
            state.item_number_generated = itemNumber;
            localStorage.setItem('item_number_generated', itemNumber);
        },
        itemListByNumber(state, single_item) {
            let items = JSON.parse(localStorage.getItem('item_recently_added_items')) || [];
            items.push(single_item);
            localStorage.setItem('item_recently_added_items', JSON.stringify(items));
        },
        itemCategory(state, category) {
            state.item_category = category;
            localStorage.setItem('item_category', category);
        },
        removeItemFromRecentList(state, itemNoToRemove) {
            const index = state.item_added_recently.find(item => item.item_no === itemNoToRemove);
            if (index !== -1) {
                state.item_added_recently.splice(index, 1);
            }
        },
        addToRecentItems(state, itemNoToAdd) {
            const items = JSON.parse(localStorage.getItem('item_recently_added_items')) || [];
            const itemToAdd = state.item_list.find(item => item.item_no === itemNoToAdd);

            if (itemToAdd) {
                items.push(itemToAdd);
                localStorage.setItem('item_recently_added_items', JSON.stringify(items));
            }
        },
        addToRecentItemsInvoice(state, selectedItems) {
            // Filter the item_list to get selected items based on item numbers
            const selectedItemsList = state.item_list.filter(item => selectedItems.includes(item.item_no));

            // Save the filtered selected items into selectedItemsList state
            state.selectedItemsList = selectedItemsList;
        },
        addRecentItemsAddPage(state, item_added) {
            state.item_added_recently.push(item_added);
        },
        clearRecentItemsAddPage(state) {
            state.item_added_recently = [];
        },
        removeFromRecentItemsInvoice(state, itemNoToRemove) {
            // Find the index of the item with the provided item_no
            const indexToRemove = state.selectedItemsList.findIndex(item => item.item_no === itemNoToRemove);
        
            if (indexToRemove !== -1) {
                // Remove the item from selectedItemsList if found
                state.selectedItemsList.splice(indexToRemove, 1);
            } else {
                console.error(`Item with item_no ${itemNoToRemove} not found in the selected items.`);
            }
        },
    }
}