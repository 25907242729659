import store from '../store';

export default function authMiddleware({next, router}) {
    const isLoggedIn = store.getters['auth/getLoggedIn'];
    const lastActiveTime = store.getters['auth/getLastActiveTime'];
    const isProfileComplete = store.getters['auth/getProfileCompleteStatus'];
    
    // Update the last active time
    try {
        store.commit('auth/updateLastActiveTime');
    } catch (error) {
        console.log(error);
    }

    // Check if the user is logged in
    if (!isLoggedIn) {
        return router.push({name: 'login'});
    }

    // if (getUserSubscriptionExpire) {
    //     try {
    //         store.dispatch('auth/logout');
    //         return router.push({name: 'login'});
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // Check if the user's session has timed out
    const sessionTimeout = store.getters['auth/getSessionTimeout'];
    const timeSinceLastActive = Date.now() - lastActiveTime;
    if (timeSinceLastActive > sessionTimeout) {
        try {
            store.dispatch('auth/logout');
        } catch (error) {
            console.log(error);
        }
        return router.push({name: 'login'});
    }

    // Check if the user's profile is complete

    if (!isProfileComplete) {
        return router.push({path: '/complete-profile'});
    }

    // Allow the user to continue with the current route
    return next();
}
