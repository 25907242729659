import ClientService from "../services/client.service";

const initialState = {
    client_list: [],
}

export const client = {
    namespaced: true,
    state: initialState,
    actions: {
        getList({ commit }, client) {
            return ClientService.getList(client)
                .then(response => {
                    commit('save', response.data);
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        getListByUser({ commit }, client) {
            return ClientService.getListByUser(client)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        createClient({ commit }, client) {
            return ClientService.createClient(client)
                .then(response => {
                    // commit('save', response.data);
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        deleteClient({ commit }, client_id) {
            return ClientService.deleteClient(client_id).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        fetchStateByCountry({ commit }, country_id) {
            return ClientService.fetchStateByCountry(country_id).then(
                response => {
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        save(state, client) {
            state.client_list = client.response;
        },
    }
}