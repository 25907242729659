<template>
  <!--dashboard main body start-->
  <div class="dashboard-quotation">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row justify-content-end mx-0">
          <form class="base-form">
            <div class="row d-flex align-items-center justify-content-between flex-row">
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="input-group input-group--3d p-0">
                  <div class="input-group-text prepend">
                    <img src="@/assets/images/system/add-user.png" />
                  </div>
                  <input type="text" name="itemTitle" class="form-control" placeholder="Type item name or title*" />
                  <div class="input-group-text transparent p-0 append flush-before">
                    <button type="button" name="btn" class="btn btn-white _sm">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div class="row d-flex align-items-center justify-content-end">
                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    <div class="input-group p-0">
                      <span class="input-group-text prepend bg_quotation flush-after">
                        Date
                      </span>
                      <input type="text" name="datepick" class="form-control datepicker" data-date-format="mm/dd/yyyy" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    <div class="input-group p-0">
                      <span class="input-group-text prepend bg_quotation flush-after">
                        Terms
                      </span>
                      <select name="paymenetMethod" class="form-select" required>
                        <option value="" selected disabled></option>
                        <option value="">10 Days</option>
                        <option value="">20 Days</option>
                        <option value="">30 Days</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="w-100 my-3 px-2">
        <div class="white-shadebox">
          <ul class="nav nav-fill nav--iconicshade">
            <li class="nav-item">
              <a href="javascript:;" class="nav-link" @click="dialogVisibleAddItem = true"><span
                  class="nav-icon shade"><img src="@/assets/images/system/plus.png" /></span>
                <span class="nav-text">Add Item</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link"><span class="nav-icon shade"><img
                    src="@/assets/images/system/percent.png" /></span><span class="nav-text">Discount</span></a>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link"><span class="nav-icon shade"><img
                    src="@/assets/images/system/camera.png" /><span class="has-badge">3</span> </span><span
                  class="nav-text">Attachment</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link"><span class="nav-icon shade"><img src="@/assets/images/system/edit.png" /><span
                    class="has-badge"><i class="fa fa-check"></i></span></span><span class="nav-text">Note</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="page-body">
        <div class="row has-vr-devider position-relative mt-5 mx-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
            <div class="contact-card-group">
              <!--repeatable block-->
              <div class="card card-flex-row _v2">
                <div class="card-header">
                  <span class="badge-count">1</span>
                </div>
                <div class="card-body">
                  <ul class="cmn-vrList">
                    <li class="list-title">Category name</li>
                    <li class="sub-title">Item name or title</li>

                    <li class="mt-2">
                      Computer Desk supplied.<br />
                      Fully software integrated system
                    </li>

                    <li class="price-tag">0x &pound;.0.00</li>
                  </ul>
                </div>
                <div class="card-footer justify-content-between flex-column align-items-end">
                  <ul class="hr-navList">
                    <li class="nav-item">
                      <a href="#" class="nav-link"><i class="fa fa-cog"></i>
                      </a>
                    </li>
                    <li class="nav-item ellipsis-dropdown dropdown">
                      <a href="#" class="nav-link dropdown-toggle no-caret" id="edit-act-1" data-bs-toggle="dropdown"
                        aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                      <ul class="dropdown-menu" aria-labelledby="edit-act-1">
                        <li>
                          <a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item delete" href="#"><i class="fa fa-times"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div class="ftr-price-tag">&pound;4000.00</div>
                </div>
              </div>
              <!--ended repeatable block-->

              <div class="card card-flex-row _v2">
                <div class="card-header">
                  <span class="badge-count">2</span>
                </div>
                <div class="card-body">
                  <ul class="cmn-vrList">
                    <li class="list-title">Category name</li>
                    <li class="sub-title">Item name or title</li>

                    <li class="mt-2">
                      Computer Desk supplied.<br />
                      Fully software integrated system
                    </li>

                    <li class="price-tag">0x &pound;.0.00</li>
                  </ul>
                </div>
                <div class="card-footer justify-content-between flex-column align-items-end">
                  <ul class="hr-navList">
                    <li class="nav-item">
                      <a href="#" class="nav-link"><i class="fa fa-cog"></i>
                      </a>
                    </li>
                    <li class="nav-item ellipsis-dropdown dropdown">
                      <a href="#" class="nav-link dropdown-toggle no-caret" id="edit-act-2" data-bs-toggle="dropdown"
                        aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                      <ul class="dropdown-menu" aria-labelledby="edit-act-2">
                        <li>
                          <a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item delete" href="#"><i class="fa fa-times"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div class="ftr-price-tag">&pound;4000.00</div>
                </div>
              </div>

              <div class="card card-flex-row _v2">
                <div class="card-header">
                  <span class="badge-count">3</span>
                </div>
                <div class="card-body">
                  <ul class="cmn-vrList">
                    <li class="list-title">Server</li>
                    <li class="sub-title">HP T430 Mount Rack Server</li>

                    <li class="mt-2">
                      HP Server Supplied.<br />
                      Fully software integrated system
                    </li>

                    <li class="price-tag">2x &pound;.2000.00</li>
                  </ul>
                </div>
                <div class="card-footer justify-content-between flex-column align-items-end">
                  <ul class="hr-navList">
                    <li class="nav-item">
                      <a href="#" class="nav-link"><i class="fa fa-cog"></i>
                      </a>
                    </li>
                    <li class="nav-item ellipsis-dropdown dropdown">
                      <a href="#" class="nav-link dropdown-toggle no-caret" id="edit-act-3" data-bs-toggle="dropdown"
                        aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                      <ul class="dropdown-menu" aria-labelledby="edit-act-3">
                        <li>
                          <a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item delete" href="#"><i class="fa fa-times"></i>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div class="ftr-price-tag">&pound;4000.00</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 ms-auto">
            <form class="base-form">
              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Amount
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Discount
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Sub-Total
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Tax (Vat) (20%)
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Tax 2 (15%)
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Total
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3">
                <div class="input-group _group-xl flush-shadow p-0">
                  <span class="input-group-text justify-content-end prepend flush-after">
                    Outstanding Due
                  </span>
                  <input type="text" name="amount" class="form-control" />
                </div>
              </div>

              <div class="row m-0 mb-3 pe-xl-5 mt-5 d-flex justify-content-end">
                <div class="col-sm-12 p-0 d-flex align-items-center justify-content-end">
                  <button class="btn btn-offWhite">Cancel</button>
                  <button class="btn btn-success btn-shadow ms-5">Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--dashboard main body end-->

  <!-- add item popup -->
  <el-dialog v-model="dialogVisibleAddItem" title="Add Item" width="60%" :before-close="handleClose">
    <div>
      
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleAddItem = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisibleAddItem = false">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";

export default {
  name: "AddQuotation",

  data() {
    return {
      loadingInstance: null,
      show_total_expense: 0,
      expenses_server_data: [],
      dialogVisibleAddItem: false,
    };
  },
  created() {

    //   this.getData();
  },
  computed: {

  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    filterExpenses() {
      console.log(this.search);
    },
  },
};
</script>
    
<style scoped></style>