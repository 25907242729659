<template>
  <div>
    <!--dashboard main body start-->
    <div class=" pt-0">
      <div class="-content d-flex align-items-start flex-column">
        <div class="page-global-header px-0">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
              <div class="global-searchbar">
                <form class="base-form">
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="@/assets/images/system/file-icon.svg" />
                    </div>
                    <input type="search" name="globalSearch" class="form-control"
                      placeholder="Search (Business name, client name, email, invoice number etc)" />
                    <button type="submit" name="btnSearch" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end">
              <ul class="search-option-menu me-3">
                <li class="nav-item">
                  <a href="#" class="nav-link list-view">
                    <img src="@/assets/images/system/list-icon.png" />
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link grid-view">
                    <img src="@/assets/images/system/grid-icon.png" />
                  </a>
                </li>
                <li class="nav-item sorting">
                  <input type="checkbox" class="sorting-check" id="btn-sorting" autocomplete="off" />
                  <label class="btn btn-sorting" for="btn-sorting"><span class="sr-only">Sort</span>
                  </label>
                </li>
                <li class="nav-item">
                  <a href="/add-invoice" class="nav-link circleNav"><i class="fa fa-plus"></i>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle no-caret" id="optionMore" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end _v1" aria-labelledby="optionMore">
                    <li>
                      <a class="dropdown-item" href="#">Email CSV/PDF/Zip<i class="fa fa-envelope append"></i>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Analytics <i class="fas fa-chart-line append"></i>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="#">Print <i class="fa fa-print append"></i></a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Download PDF/CSV
                        <i class="fa fa-download append"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 mx-auto mt-3">
              <div class="global-filter-form">
                <form class="base-form form-xl">
                  <div class="row g-2 d-flex justify-content-center">
                    <div class="col-sm-2">
                      <div class="form-floating">
                        <input type="text" name="datepick" id="startDate"
                          class="form-control datepicker text-center text-danger radius-tr-0 radius-br-0 _3d-input chevron-circle"
                          data-date-format="mm/dd/yyyy" />
                        <label for="startDate" class="w-100 text-center">Start date</label>
                      </div>
                    </div>

                    <div class="col-sm-2">
                      <div class="form-floating">
                        <input type="text" name="datepick" id="endDate"
                          class="form-control datepicker text-center text-danger radius-tl-0 radius-bl-0 _3d-input chevron-circle"
                          data-date-format="mm/dd/yyyy" />
                        <label for="endDate" class="w-100 text-center">End date</label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <select class="form-select _3d-input">
                        <option selected disabled>
                          Select invoice category
                        </option>
                        <option value="1">Category item one</option>
                        <option value="2">Category item two</option>
                        <option value="3">Category item three</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="page-body">
          <div class="row justify-content-center">
            <!--repeatable block-->
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
              <div class="card-list-group">
                  <!--repeatable block-->
                  <div class="card card-horizontal-list javaBlue">
                      <div class="card-body">
                          <div class="hover-slide">
                              <div class="form-check outliner">
                                  <input class="form-check-input" type="checkbox" value="">
                              </div>
                          </div>
                          <div class="card-content flex-shrink-0">
                              <span class="card-count">1.</span>
                              <ul class="label-list">

                                  <li class="list-title">
                                      John Mechanics
                                  </li>
                                  <li class="sub-title">IN: 123456789</li>
                                  <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                                  <li>E: johnmechanics@email.com</li>
                              </ul>
                              <ul class="card-action-nav flex-start-horizontal mt-3">
                                  <li class="nav-item"><a href="#" class="btn btn-alert btn-sm"><i class="fa fa-dollar-sign"></i> P/Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-success btn-sm"><i class="fa fa-dollar-sign"></i> Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-azureBlue btn-sm"><i class="fa fa-dollar-sign"></i> Nill </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-secondary btn-sm"><i class="fa fa-percent"></i> Discount </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i class="fa fa-paperclip"></i> File </a> </li>
                                  <li class="nav-item ms-2"><a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note </a> </li>
                              </ul>
                          </div>

                      </div>
                      <div class="card-footer has-action flex-shrink-1">
                          <div class="ellipsis-dropdown dropdown">
                              <button class="btn dropdown-toggle no-caret" type="button" id="card-dropAction-22" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="card-dropAction-22">
                                  <li><a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i> </a></li>
                                  <li><a class="dropdown-item delete" href="#"><i class="fa fa-times"></i> </a></li>

                              </ul>
                          </div>
                          <ul class="log-status _sm center">
                              <li class="list-item overdue"><a href="#"><i class="fas fa-hourglass-end"></i> Due</a> </li>
                          </ul>
                          <div class="footer-list-wraper justify-content-end">
                              <ul class="footer-list has-border-bottom w-auto ps-2">
                                  <li>Total</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>

                              <ul class="footer-list highlight has-border-bottom w-auto ps-2">
                                  <li>Paid</li>
                                  <li class="count">-£0.00</li>
                              </ul>

                              <ul class="footer-list _big w-auto ps-2">
                                  <li>Balance Due</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>
                          </div>
                      </div>

                  </div>
                  <!--ended repeatable block-->
                  <div class="card card-horizontal-list javaBlue">
                      <div class="card-body">
                          <div class="hover-slide">
                              <div class="form-check outliner">
                                  <input class="form-check-input" type="checkbox" value="">
                              </div>
                          </div>
                          <div class="card-content flex-shrink-0">
                              <span class="card-count">1.</span>
                              <ul class="label-list">

                                  <li class="list-title">
                                      John Mechanics
                                  </li>
                                  <li class="sub-title">IN: 123456789</li>
                                  <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                                  <li>E: johnmechanics@email.com</li>
                              </ul>
                              <ul class="card-action-nav flex-start-horizontal mt-3">
                                  <li class="nav-item"><a href="#" class="btn btn-alert btn-sm"><i class="fa fa-dollar-sign"></i> P/Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-success btn-sm"><i class="fa fa-dollar-sign"></i> Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-azureBlue btn-sm"><i class="fa fa-dollar-sign"></i> Nill </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-secondary btn-sm"><i class="fa fa-percent"></i> Discount </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i class="fa fa-paperclip"></i> File </a> </li>
                                  <li class="nav-item ms-2"><a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note </a> </li>
                              </ul>
                          </div>

                      </div>
                      <div class="card-footer has-action flex-shrink-1">
                          <div class="ellipsis-dropdown dropdown">
                              <button class="btn dropdown-toggle no-caret" type="button" id="card-dropAction-24" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="card-dropAction-24">
                                  <li><a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i> </a></li>
                                  <li><a class="dropdown-item delete" href="#"><i class="fa fa-times"></i> </a></li>

                              </ul>
                          </div>
                          <ul class="log-status _sm center">
                              <li class="list-item void"><a href="#"><i class="fas fa-ban"></i> Void</a> </li>
                          </ul>
                          <div class="footer-list-wraper justify-content-end">
                              <ul class="footer-list has-border-bottom w-auto ps-2">
                                  <li>Total</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>

                              <ul class="footer-list highlight has-border-bottom w-auto ps-2">
                                  <li>Paid</li>
                                  <li class="count">-£0.00</li>
                              </ul>

                              <ul class="footer-list _big w-auto ps-2">
                                  <li>Balance Due</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>
                          </div>
                      </div>

                  </div>

                  <div class="card card-horizontal-list javaBlue">
                      <div class="card-body">
                          <div class="hover-slide">
                              <div class="form-check outliner">
                                  <input class="form-check-input" type="checkbox" value="">
                              </div>
                          </div>
                          <div class="card-content flex-shrink-0">
                              <span class="card-count">1.</span>
                              <ul class="label-list">

                                  <li class="list-title">
                                      John Mechanics
                                  </li>
                                  <li class="sub-title">IN: 123456789</li>
                                  <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                                  <li>E: johnmechanics@email.com</li>
                              </ul>
                              <ul class="card-action-nav flex-start-horizontal mt-3">
                                  <li class="nav-item"><a href="#" class="btn btn-alert btn-sm"><i class="fa fa-dollar-sign"></i> P/Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-success btn-sm"><i class="fa fa-dollar-sign"></i> Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-azureBlue btn-sm"><i class="fa fa-dollar-sign"></i> Nill </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-secondary btn-sm"><i class="fa fa-percent"></i> Discount </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i class="fa fa-paperclip"></i> File </a> </li>
                                  <li class="nav-item ms-2"><a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note </a> </li>
                              </ul>
                          </div>

                      </div>
                      <div class="card-footer has-action flex-shrink-1">
                          <div class="ellipsis-dropdown dropdown">
                              <button class="btn dropdown-toggle no-caret" type="button" id="card-dropAction-25" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="card-dropAction-25">
                                  <li><a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i> </a></li>
                                  <li><a class="dropdown-item delete" href="#"><i class="fa fa-times"></i> </a></li>

                              </ul>
                          </div>
                          <ul class="log-status _sm center">
                              <li class="list-item draft"><a href="#"><i class="far fa-file-alt"></i> Draft</a> </li>
                          </ul>
                          <div class="footer-list-wraper justify-content-end">
                              <ul class="footer-list has-border-bottom w-auto ps-2">
                                  <li>Total</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>

                              <ul class="footer-list highlight has-border-bottom w-auto ps-2">
                                  <li>Paid</li>
                                  <li class="count">-£0.00</li>
                              </ul>

                              <ul class="footer-list _big w-auto ps-2">
                                  <li>Balance Due</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>
                          </div>
                      </div>

                  </div>

                  <div class="card card-horizontal-list javaBlue">
                      <div class="card-body">
                          <div class="hover-slide">
                              <div class="form-check outliner">
                                  <input class="form-check-input" type="checkbox" value="">
                              </div>
                          </div>
                          <div class="card-content flex-shrink-0">
                              <span class="card-count">1.</span>
                              <ul class="label-list">

                                  <li class="list-title">
                                      John Mechanics
                                  </li>
                                  <li class="sub-title">IN: 123456789</li>
                                  <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                                  <li>E: johnmechanics@email.com</li>
                              </ul>
                              <ul class="card-action-nav flex-start-horizontal mt-3">
                                  <li class="nav-item"><a href="#" class="btn btn-alert btn-sm"><i class="fa fa-dollar-sign"></i> P/Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-success btn-sm"><i class="fa fa-dollar-sign"></i> Paid </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-azureBlue btn-sm"><i class="fa fa-dollar-sign"></i> Nill </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-secondary btn-sm"><i class="fa fa-percent"></i> Discount </a> </li>
                                  <li class="nav-item"><a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i class="fa fa-paperclip"></i> File </a> </li>
                                  <li class="nav-item ms-2"><a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note </a> </li>
                              </ul>
                          </div>

                      </div>
                      <div class="card-footer has-action flex-shrink-1">
                          <div class="ellipsis-dropdown dropdown">
                              <button class="btn dropdown-toggle no-caret" type="button" id="card-dropAction-26" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-ellipsis-v"></i>
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="card-dropAction-26">
                                  <li><a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i> </a></li>
                                  <li><a class="dropdown-item delete" href="#"><i class="fa fa-times"></i> </a></li>

                              </ul>
                          </div>
                          <ul class="log-status _sm center">
                              <li class="list-item overdue"><a href="#"><i class="fas fa-hourglass-end"></i> Due</a> </li>
                          </ul>
                          <div class="footer-list-wraper justify-content-end">
                              <ul class="footer-list has-border-bottom w-auto ps-2">
                                  <li>Total</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>

                              <ul class="footer-list highlight has-border-bottom w-auto ps-2">
                                  <li>Paid</li>
                                  <li class="count">-£0.00</li>
                              </ul>

                              <ul class="footer-list _big w-auto ps-2">
                                  <li>Balance Due</li>
                                  <li class="count">&pound;100.00</li>
                              </ul>
                          </div>
                      </div>

                  </div>



              </div>
          </div>
          </div>

          <div class="row mx-0 mt-5">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
                <div class="d-flex justify-content-end">
                    <div class="card-totalCount card-totalCount--total">
                        <div class="card-header">Total</div>
                        <div class="card-body">
                            &pound;100.00
                        </div>
                    </div>
                    <div class="card-totalCount card-totalCount--paid ms-4">
                        <div class="card-header">Paid</div>
                        <div class="card-body">
                            &pound;100.00
                        </div>
                    </div>

                    <div class="card-totalCount card-totalCount--ballance ms-4">
                        <div class="card-header">Ballance Due</div>
                        <div class="card-body">
                            &pound;100.00
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-center">
                <button type="button" name="btn" class="btn btn-offWhite">Close</button>
            </div>
        </div>
        </div>
      </div>
    </div>
    <!--dashboard main body end-->

    <!--Expense File Preview Modal-->
    <div class="modal fade mdl-messaging _info" id="expenseFile" aria-hidden="true" aria-labelledby="expenseFileLabel"
      tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-dialog-header">
          <div class="header-title">Expense preview</div>
        </div>
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="message-card no-shadow m-0">
              <div class="abs-nav abs--tr me-n5">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-edit"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-print"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#"><i class="fa fa-envelope"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fa fa-download"></i> </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link"><i class="fas fa-ban"></i> </a>
                  </li>
                </ul>
              </div>

              <div class="w-100">
                <ul class="list-group list-group--borderless list-group--compact">
                  <li class="list-group-item">Expense number: 0123456789</li>
                  <li class="list-group-item">Category: Office travel</li>
                  <li class="list-group-item">Vendor name: Holiday Inn</li>
                  <li class="list-group-item">Date: 12/12/2022</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span>Payment method: Cash</span>
                    <span>Amount: £100.00</span>
                  </li>
                </ul>
              </div>

              <div class="card card--curve-05rem mt-3">
                <div class="card-body">
                  <p class="m-0">Note:</p>
                  <p class="m-0">
                    Case veri labore mel eu. Assum veritus at quo. Ut per
                    aliquam inermis deseruisse, qui nisl antiopam scripserit at,
                    quis feugait nostrum mei id. Errem altera putent ad eum,
                    hinc insolens recusabo et sit.
                  </p>
                </div>
              </div>

              <div class="card card--curve-05rem mt-3 w-100 align-items-center justify-content-center">
                <div class="w-auto p-2">
                  <img src="@/assets/images/system/receipt.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_INVOICE } from "../../config/url";


export default {
  name: "InvoiceComponent",

  data() {
    return {
      loadingInstance: null,
      show_total_expense: 0,
      invoice_server_data: [],
    };
  },
  created() {

    this.getData();
  },
  computed: {

  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    filterinvoice() {
      console.log(this.search);
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("invoice/getList", this.form)
        .then((res) => {
          if (res.data.status == 1) {
            console.loog(res)
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$toast.error("Resource not found");
          }
          // this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
  
<style scoped></style>