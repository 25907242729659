<template>
  <!--dashboard main body start-->
  <div>
      <div class="-content d-flex align-items-start flex-column">
        <div class="page-global-header px-0">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
              <div class="global-searchbar">
                <form class="base-form">
                  <div class="input-group">
                    <div class="input-group-text">
                      <img src="@/assets/images/system/file-icon.svg" />
                    </div>
                    <input type="search" name="globalSearch" class="form-control"
                      placeholder="Search (Business name, client name, email, invoice number etc)" />
                    <button type="submit" name="btnSearch" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end">
              <ul class="search-option-menu me-3">
                <li class="nav-item">
                  <a href="#" class="nav-link list-view">
                    <img src="@/assets/images/system/list-icon.png" />
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link grid-view">
                    <img src="@/assets/images/system/grid-icon.png" />
                  </a>
                </li>
                <li class="nav-item sorting">
                  <input type="checkbox" class="sorting-check" id="btn-sorting" autocomplete="off" />
                  <label class="btn btn-sorting" for="btn-sorting"><span class="sr-only">Sort</span>
                  </label>
                </li>
                <li class="nav-item">
                  <a href="/add-quotation" class="nav-link circleNav"><i class="fa fa-plus"></i>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a href="#" class="nav-link dropdown-toggle no-caret" id="optionMore" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end _v1" aria-labelledby="optionMore">
                    <li>
                      <a class="dropdown-item" href="#">Email CSV/PDF/Zip<i class="fa fa-envelope append"></i>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Analytics <i class="fas fa-chart-line append"></i>
                      </a>
                    </li>

                    <li>
                      <a class="dropdown-item" href="#">Print <i class="fa fa-print append"></i></a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Download PDF/CSV <i class="fa fa-download append"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 mx-auto mt-3">
              <div class="global-filter-form">
                <form class="base-form form-xl">
                  <div class="row g-2 d-flex justify-content-center">
                    <div class="col-sm-2">
                      <div class="form-floating">
                        <input type="text" name="datepick" id="startDate"
                          class="form-control datepicker text-center text-danger radius-tr-0 radius-br-0 _3d-input chevron-circle"
                          data-date-format="mm/dd/yyyy" />
                        <label for="startDate" class="w-100 text-center">Start date</label>
                      </div>
                    </div>

                    <div class="col-sm-2">
                      <div class="form-floating">
                        <input type="text" name="datepick" id="endDate"
                          class="form-control datepicker text-center text-danger radius-tl-0 radius-bl-0 _3d-input chevron-circle"
                          data-date-format="mm/dd/yyyy" />
                        <label for="endDate" class="w-100 text-center">End date</label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <select class="form-select _3d-input">
                        <option selected disabled>
                          Select quotation category (all)
                        </option>
                        <option value="1">Category item one</option>
                        <option value="2">Category item two</option>
                        <option value="3">Category item three</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="page-body">
          <div class="row mx-0">
            <!--repeatable block-->
            <div class="col-sm-7 mt-2" v-for="n in 2" :key="n">
              <!--repeatable block-->
              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">1.</span>
                    <ul class="label-list">
                      <li class="list-title">John Mechanics</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: johnmechanics@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item sent">
                      <a href="#"><i class="fas fa-paper-plane"></i> Sent</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--ended repeatable block-->

              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">2.</span>
                    <ul class="label-list">
                      <li class="list-title">Mike's Barber</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: mikesbarber@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item approved">
                      <a href="#"><i class="fas fa-check-square"></i> Approved</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">3.</span>
                    <ul class="label-list">
                      <li class="list-title">Rick's Plumbing</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: ricksplumbing@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item rejected">
                      <a href="#"><i class="fas fa-times-circle"></i> Rejected</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">4.</span>
                    <ul class="label-list">
                      <li class="list-title">Jim's Garage</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: jimsgarage@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item expired">
                      <a href="#"><i class="fas fa-clock"></i> Expired</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">5.</span>
                    <ul class="label-list">
                      <li class="list-title">Roman Corner Shop</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: romanshop@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item draft">
                      <a href="#"><i class="far fa-file-alt"></i> Draft</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="card card-horizontal-list quotationBrown">
                <div class="card-body">
                  <div class="hover-slide">
                    <div class="form-check outliner">
                      <input class="form-check-input" type="checkbox" value="" />
                    </div>
                  </div>
                  <div class="card-content">
                    <span class="card-count">6.</span>
                    <ul class="label-list">
                      <li class="list-title">Peter Coffee Shop</li>
                      <li class="sub-title">QN: 0123456789</li>
                      <li class="text-danger">D: 12/12/2021 - 12/12/2022</li>
                      <li>E: petercoffee@gmail.com</li>
                    </ul>
                    <ul class="card-action-nav flex-start-horizontal mt-3">
                      <li class="nav-item">
                        <a href="#" class="btn btn-secondary btn-sm" data-bs-toggle="modal"
                          data-bs-target="#expenseFile"><i class="fas fa-percent"></i> Discount
                        </a>
                      </li>
                      <li class="nav-item">
                        <a href="#" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#expenseFile"><i
                            class="fa fa-paperclip"></i> File
                        </a>
                      </li>
                      <li class="nav-item ms-2">
                        <a href="#" class="btn btn-info btn-sm"><i class="fa fa-edit"></i> Note
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-footer has-action flex-shrink-1">
                  <ul class="log-status center">
                    <li class="list-item void">
                      <a href="#"><i class="fas fa-ban"></i> Void</a>
                    </li>
                  </ul>
                  <div class="footer-list-wraper justify-content-end">
                    <ul class="footer-list _big">
                      <li>Amount</li>
                      <li class="count">&pound;100.00</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-0 mt-5">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
              <div class="px-5 d-flex justify-content-end">
                <div class="card-totalCount card-totalCount--sent">
                  <div class="card-header">Sent</div>
                  <div class="card-body">&pound;100.00</div>
                </div>

                <div class="card-totalCount card-totalCount--approved ms-4">
                  <div class="card-header">Approved</div>
                  <div class="card-body">&pound;100.00</div>
                </div>
              </div>

              <div class="px-5 d-flex justify-content-end mt-5">
                <button type="button" name="btn" class="btn btn-offWhite">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <!--dashboard main body end-->
</template>
<script>
import { CURRENCY_LIST } from "../../config/url";

export default {
  name: "QuotationComponent",

  data() {
    return {
      loadingInstance: null,
      show_total_expense: 0,
      quotation_server_data: [],
      expense_category: null,
      currency: null,
      payment_method: null,
      form: {
        category_id: "",
      },
      expenses_preview: {
        id: "",
        expense_number: "",
        category: "",
        vendor: "",
        date: "",
        payment_method: "",
        note: "",
        currency_id: "",
        amount: "",
        image_url: [],
      },
      search: {
        date: "",
        category: "",
        currency: "",
        payment_method: "",
      },
      file_data: "",
      file_popup: false,
      note_popup: false,
    };
  },
  created() {

    this.fetchCurrency();
    this.getData();
  },
  computed: {

    currencyOptions() {
      if (this.currency) {
        return this.currency.map((v) => {
          return {
            id: v.id,
            name: v.name,
            symbol: v.symbol,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    getData() {
      // this.showLoading();
      // this.$store
      //   .dispatch("quotation/getList", this.form)
      //   .then((res) => {
      //     if (res.data.status == 1) {
      //       this.quotation_server_data = res.data.response;
      //       // this.$toast.success("Fetch data Successful.");
      //       // this.$router.push({ name: 'user-dashboard' });
      //     } else {
      //       throw new Error(res);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.$toast.error("Something went wrong. Please try again.");
      //   })
      //   .finally(() => {
      //     this.hideLoading();
      //   });
    },
    async fetchCurrency() {
      this.currency = await this.axios
        .get(CURRENCY_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterExpenses() {
      console.log(this.search);
    },
  },
};
</script>
  
<style scoped>
.expenses-list-body .expenses-list-single {
  min-height: 150px;
}
</style>